import axios from "axios";
import React, { useEffect, useState } from "react";

const HeaderManager = () => {
  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [userInfo, setUserInfo] = useState<any>(null);

  useEffect(() => {
    requestUserInfo();
  }, []);

  function doLogout() {
    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  }

  const copyLink = async () => {

    const link = "http://vibex-global.com/register/" + userInfo?.referralCode;

    if (!navigator.clipboard) {
      // Fallback for iOS
      const el = document.createElement("textarea");
      el.value = link;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      return;
    }
    // For modern browsers supporting Clipboard API
    navigator.clipboard
      .writeText(link)
      .then(() => {
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  const requestUserInfo = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/user/info";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      //console.log(response);
      if (response.data.code == 0) {
        setUserInfo(response.data.data);
      }
    } catch (error) {}
  };

  return (
    <section>
      <div className="manager-header">
        <a onClick={copyLink} className="code" data-lang="nav_code">Invitation Code</a>
        <a href="#" className="user">{userInfo?.name}</a>
        <span className="text-red-500 ml-4 cursor-pointer" onClick={doLogout}>Log out</span>
      </div>
      <div className="manager-nav">
        <a href="/affiliates">Home</a>
        <a href="/affiliates/partner" data-lang="nav_partner">
          Partners Status
        </a>
        <a href="/affiliates/members" data-lang="nav_member">
          Members Managements
        </a>
        <a href="/affiliates/trading" data-lang="nav_trading">
          Trading History
        </a>
        <a href="/affiliates/history" data-lang="nav_settlement">
          Settlement History
        </a>
        <a href="/affiliates/withdraw" data-lang="nav_withdrawal">
          Withdrawl
        </a>
        <a href="/affiliates/security" data-lang="nav_security">
          Security Settings
        </a>
      </div>
    </section>
  );
};

export default HeaderManager;
