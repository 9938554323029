import { useEffect, useState } from "react";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import Header from "../components/ui/Header";
import axios from "axios";
import goImage from "../assets/images/sys_icon_go.svg";

const Profile = () => {
  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [userInfo, setUserInfo] = useState<any>(null);
  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    requestUserInfo();
  }, []);

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  function doLogout() {
    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  }

  const requestUserInfo = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/user/info";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.code == 0) {
        setUserInfo(response.data.data);
      } else {
        if (response.data.code === 40000) {
          localStorage.removeItem("accessToken");
          window.location.href = "/login";
        }
      }
    } catch (error) {}
  };

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="w-full h-full">
        <div className="w-full max-w-[400px] mx-auto">
          <section className="relative flex flex-col justify-start items-center mt-10 px-5 py-5">
            <div className="w-[50px] h-[50px] mt-5 rounded-full bg-[var(--t-color-point)]"></div>
            <span className="mt-3 text-white text-[1rem] font-bold">{userInfo?.name}</span>
            <span className="text-white text-[0.8rem]">{userInfo?.email}</span>
          </section>
          <section className="w-full px-5">
            <div
              className="flex flex-row justify-between items-center w-full h-[60px]"
              onClick={doLogout}
            >
              <span className="text-red-500 font-bold text-[0.8rem]">Log out</span>
              <img src={goImage} alt="" className="w-[15px] h-[15px]" />
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};

export default Profile;
