import React, { useState, useCallback, useEffect } from "react";
import "../assets/css/Manager.css";
import HeaderManager from "../components/ui/HeaderManager";
import axios from "axios";
import {
  formatDate,
  formatNumberWithCommas,
  formatNumberWithNumber,
  getLevelName,
} from "../components/common/Utils";
import AlertPopUp from "../components/ui/AlertPopUp";
import { useNavigate } from "react-router-dom";

const Manager = () => {
  const navigate = useNavigate();

  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [data, setData] = useState<any>(null);
  // AlertPopUp
  const [isOpenAlertPopUp, setIsOpenAlertPopUp] = useState(false);
  const [alertPopUpDesc, setAlertPopUpDesc] = useState<string>("");

  useEffect(() => {
    const level = localStorage.getItem("level");
    let levelNumber: number = 1;
    if (level != null) {
      levelNumber = parseInt(level);
    }
    console.log(level);
    if (levelNumber < 2) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    requestManagerLookup();
  }, []);

  const copyLink = async () => {
    const link = "http://vibex-global.com/register/" + data?.referralCode;

    if (!navigator.clipboard) {
      // Fallback for iOS
      const el = document.createElement("textarea");
      el.value = link;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      showAlertPopup("Copied to clipboard");
      return;
    }
    // For modern browsers supporting Clipboard API
    navigator.clipboard
      .writeText(link)
      .then(() => {
        showAlertPopup("Copied to clipboard");
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  const showAlertPopup = (popupDesc: string) => {
    setAlertPopUpDesc(popupDesc);
    setIsOpenAlertPopUp(true);
  };

  const hideAlertPopup = () => {
    setIsOpenAlertPopUp(false);
    setAlertPopUpDesc("");
  };

  /**
   * api
   */
  const requestManagerLookup = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/manager/home";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      if (response.data.code === 0) {
        setData(response.data.data);
      }
    } catch (error) {}
  };

  return (
    <div className="w-full h-full">
      <section className="w-full manager">
        <HeaderManager />
        <section className="main">
          <h1>
            DASHBOARD<strong className="today"></strong>
          </h1>
          <section className="dashboard">
            <div className="cell pink">
              <h3 data-lang="home_influencer" className="th">
                Influencer (Rating)
              </h3>
              <h2>{getLevelName(data?.level)}</h2>
            </div>
            <div className="cell sky">
              <h3 data-lang="home_referrals" className="th">
                Referrals Master
              </h3>
              <h2>
                {data?.name}({getLevelName(data?.level)})
              </h2>
            </div>

            <div className="cell green">
              <h3 data-lang="home_referrals_sub">Sub Referrals</h3>
              <h2>{formatNumberWithNumber(data?.belowReferCount)}</h2>
            </div>

            <div className="cell pur">
              <h3 data-lang="home_today" className="th">
                Members on Today
              </h3>
              <h2>{formatNumberWithNumber(data?.todayJoinCount)}</h2>
            </div>

            <div className="cell yellow">
              <h3 data-lang="home_week" className="th">
                Members on This Week
              </h3>
              <h2>{formatNumberWithNumber(data?.weekJoinCount)}</h2>
            </div>

            <div className="cell blue">
              <h3 data-lang="home_join_date" className="th">
                Join Date
              </h3>
              <h2>{formatDate(data?.insertDate)}</h2>
            </div>

            <div className="cell vio">
              <h3 data-lang="home_members_sub" className="th">
                Sub Members
              </h3>
              <h2>{formatNumberWithNumber(data?.belowMemberCount)}</h2>
            </div>

            <div className="cell pea">
              <h3 data-lang="home_manager" className="th">
                Manager
              </h3>
              <h2>{data?.name}</h2>
            </div>

            <div className="cell orange">
              <h3 data-lang="home_phone" className="th">
                Phone Number
              </h3>
              <h2>{data?.phone}</h2>
            </div>

            <div className="cell lightgreen mail">
              <h3 data-lang="home_email" className="th">
                E-mail
              </h3>
              <h2>{data?.userid}</h2>
            </div>

            <div className="cell copy link">
              <button onClick={copyLink}>
                http://vibex-global.com/register/{data?.referralCode}
              </button>
            </div>
          </section>

          <h1>ASSETS</h1>
          <section className="dashboard">
            <div className="cell total">
              <h3 data-lang="home_assets_amount">Holding Amounts</h3>
              <h4 className="futures">{formatNumberWithCommas(data?.userBalance.usdtm)}</h4>
              <h4 className="bsc">{formatNumberWithCommas(data?.userBalance.bsc)}</h4>
              <h4 className="usdt">{formatNumberWithCommas(data?.userBalance.usdt)}</h4>
            </div>

            <div className="cell total">
              <h3 data-lang="home_assets_amount_sub">Lower Members Holdings</h3>
              <h4 className="futures">{formatNumberWithCommas(data?.belowMemberBalance.usdtm)}</h4>
              <h4 className="btc">{formatNumberWithCommas(data?.belowMemberBalance.bsc)}</h4>
              <h4 className="usdt">{formatNumberWithCommas(data?.belowMemberBalance.usdt)}</h4>
            </div>
          </section>

          <section className="dashboard col-3">
            <div className="cell">
              <h3 data-lang="home_assets_partners">Sub Partners</h3>
              <h2 className="two">{formatNumberWithNumber(data?.belowReferCount)}</h2>
            </div>

            <div className="cell">
              <h3 data-lang="home_assets_partners_sub">Members of Sub Partners</h3>
              <h2 className="two">{formatNumberWithNumber(data?.belowMemberCount)}</h2>
            </div>

            <div className="cell">
              <h3 data-lang="home_assets_trading_volume">Daily Trading Volumes</h3>
              <h4 className="btc">{formatNumberWithCommas(data?.todayOrderVolumes)}</h4>
            </div>

            <div className="cell">
              <h3 data-lang="home_assets_trading_volume">Daily Option Trading Volumes</h3>
              <h4 className="btc">{formatNumberWithCommas(data?.todayOptionOrderVolumes)}</h4>
            </div>

            <div className="cell">
              <h3 data-lang="home_assets_members_sub">Total Trading volumes</h3>
              <h4 className="btc">{formatNumberWithCommas(data?.totalOrderVolumes)}</h4>
            </div>

            <div className="cell">
              <h3 data-lang="home_assets_members_sub">Total Option Trading volumes</h3>
              <h4 className="btc">{formatNumberWithCommas(data?.totalOptionOrderVolumes)}</h4>
            </div>

            <div className="cell">
              <h3 data-lang="home_assets_deposit_daily">Daily Deposits</h3>
              <h4 className="btc">{formatNumberWithCommas(data?.todayDeposit)}</h4>
            </div>

            <div className="cell">
              <h3 data-lang="home_assets_withdrawal_daily">Daily Withdrawals</h3>
              <h4 className="btc">{formatNumberWithCommas(data?.todayWithdraw)}</h4>
            </div>

            <div className="cell">
              <h3 data-lang="home_assets_deposit">Total Deposits</h3>
              <h4 className="btc">{formatNumberWithCommas(data?.totalDeposit)}</h4>
            </div>

            <div className="cell">
              <h3 data-lang="home_assets_withdrawal">Total Withdrawals</h3>
              <h4 className="btc">{formatNumberWithCommas(data?.totalWithdraw)}</h4>
            </div>

            <div className="cell">
              <h3 data-lang="home_assets_trading">Total withdraw partners</h3>
              <h4 className="btc">{formatNumberWithCommas(data?.withdrawPartnerSum)}</h4>
            </div>
          </section>
        </section>
        {isOpenAlertPopUp && <AlertPopUp message={alertPopUpDesc} onClose={hideAlertPopup} />}
      </section>
    </div>
  );
};

export default Manager;
