import React, { useState, useCallback, useEffect } from "react";
import Header from "../components/ui/Header";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import "../assets/css/Register.css";
import ToastPopUp from "../components/ui/ToastPopUp";
import axios from "axios";
import { useParams } from "react-router-dom";

const Register = () => {
  const { code } = useParams();
  
  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [isMenuOpen, setMenuOpen] = useState(false);
  // userod
  const [userid, setUserid] = useState("");
  // password
  const [password, setPassword] = useState("");
  // confirmPassword
  const [confirmPassword, setConfirmPassword] = useState("");
  // name
  const [name, setName] = useState("");
  // phone
  const [phone, setPhone] = useState("");
  // ReferralCode
  const [referralCode, setReferralCode] = useState("");
  // ToastPopUp
  const [toastPopUpHidden, setToastPopUpHidden] = useState<string>("hidden");
  const [toastPopUpDesc, setToastPopUpDesc] = useState<string>("");

  useEffect(() => {
    if (code != undefined) {
      setReferralCode(code);
    }
  }, []);

  const useridChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Use a regular expression to allow only English letters
    const regex = /^[A-Za-z0-9!@#$%^&*(),.?":{}|<>]*$/;

    if (regex.test(inputValue)) {
      setUserid(inputValue);  // No error here
    }
  };

  const passwordChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setPassword(event.target.value);
  };

  const confirmPasswordChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setConfirmPassword(event.target.value);
  };

  const nameChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setName(event.target.value);
  };

  const phoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setPhone(formatPhoneNumber(value));
  };

  // Function to format the input as a phone number
  const formatPhoneNumber = (input: string): string => {
    // Remove all non-digit characters from the input
    const cleaned = input.replace(/\D/g, '');

    // Match the cleaned number into parts using regex
    const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);

    // If match found, format the number
    if (match) {
        return `${match[1]}-${match[2]}-${match[3]}`;
    }

    // Return original input if no match found (handle unexpected input gracefully)
    return input;
  };

  const referralCodeChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setReferralCode(event.target.value);
  };

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  function goLogin() {
    window.location.href = "/login";
  }

  const showPopup = (popupDesc: string) => {
    setToastPopUpHidden("");
    setToastPopUpDesc(popupDesc);
    hidePopup();
  };

  const hidePopup = () => {
    setTimeout(function () {
      setToastPopUpHidden("hidden");
      setToastPopUpDesc("");
    }, 2000);
  };

  const doRegister = () => {
    if (userid.length === 0) {
      showPopup("Please enter your email.");
      return;
    }
    let pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    if (!pattern.test(userid)) {
      showPopup("Please Input your ID as an email.");
      return;
    }
    if (password.length === 0) {
      showPopup("Please enter your password.");
      return;
    }
    if (confirmPassword.length === 0) {
      showPopup("Please enter your confirm password.");
      return;
    }
    if (password !== confirmPassword) {
      showPopup('Passwords do not match');
      return;
    }
    if (name.length === 0) {
      showPopup("Please enter your name.");
      return;
    }
    if (phone.length === 0) {
      showPopup("Please enter your phone number.");
      return;
    }
    if (referralCode.length === 0) {
      showPopup("Please enter your referral code.");
      return;
    }
    requestRegister();
  };

  const requestRegister = async (): Promise<void> => {
    try {
      const response = await axios.post(hostUrl + "/auth/register", {
        userid,
        password,
        name,
        phone,
        referralCode,
      });
      if (response.status === 200) {
        if (response.data.code === 0) {
          showPopup("Successfully registered.");
          setTimeout(function () {
            window.location.href = "/login";
          }, 1000);
        } else {
          showPopup(response.data.message);
        }
      } else {
        showPopup("Failed to register.");
      }
      //window.location.replace("/login");
    } catch (error) {}
  };

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="flex flex-row justify-center w-full h-full bg-white">
        <div className="register">
          <h1>Welcome to Vibex</h1>
          <div className="px-10 mt-5">
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
              Email (UserID)
            </label>
            <input
              type="text"
              autoComplete="off"
              className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
              placeholder="Email"
              value={userid}
              onChange={useridChange}
              required
            />
          </div>
          <div className="px-10 mt-5">
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
              Password
            </label>
            <input
              type="password"
              autoComplete="off"
              className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
              placeholder="Password"
              value={password}
              onChange={passwordChange}
              required
            />
          </div>
          <div className="px-10 mt-5">
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
              Confirm Password
            </label>
            <input
              type="password"
              autoComplete="off"
              className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={confirmPasswordChange}
              required
            />
          </div>
          <div className="px-10 mt-5">
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
              Name
            </label>
            <input
              type="text"
              autoComplete="off"
              className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
              placeholder="Name"
              value={name}
              onChange={nameChange}
              required
            />
          </div>
          <div className="px-10 mt-5">
            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-black">
              Phone
            </label>
            <input
              type="text"
              autoComplete="off"
              className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
              placeholder="Phone"
              value={phone}
              onChange={phoneChange}
              required
            />
          </div>
          <div className="px-10 mt-5">
            {
              /*
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
              Referral Code
            </label>
              */
            }
            <input
              type="hidden"
              autoComplete="off"
              className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
              placeholder="Referral Code"
              value={referralCode}
              onChange={referralCodeChange}
              required
            />
          </div>
          <div className="px-10 mt-10">
            <button
              className="w-full h-[50px] bg-[var(--t-color-point)] text-lg text-white rounded-lg"
              onClick={doRegister}
            >
              Create Account
            </button>
          </div>
          <h3 className="mt-5 text-sm text-center" onClick={goLogin}>
            Already have an account?
            <span className="ml-1 font-bold text-[var(--t-color-point)] cursor-pointer">
              Log In
            </span>
          </h3>
        </div>
      </section>
      <div className="flex flex-row justify-center w-full h-auto">
        <ToastPopUp hidden={toastPopUpHidden} desc={toastPopUpDesc} />
      </div>
    </div>
  );
};

export default Register;
